import React from 'react';
import App from 'next/app';
import Router from 'next/router';
import { I18nextProvider } from 'react-i18next';
import { ApolloProvider } from '@apollo/client';
import { DefaultSeo } from 'next-seo';
import NProgress from 'nprogress';
import reduxWrapper from '../store';
import i18n from '../utils/i18n';
import { createSeoConfig } from '../utils/helpers';
import ErrorBoundary from '../components/ErrorBoundary';
import withApolloClient from '../utils/with-apollo-client';
import countersLoader from '../utils/counters';
import CONFIG from '../config';
import HeadMeta from '../components/HeadMeta/HeadMeta';
import ErrorPage from '../components/ErrorPage';
import adriverCallback from '../components/Banner/genericCallback';
import BridgeProvider from '../bridge';
import '../styles/index.scss';

const { gtmContainerId, fbAppId } = CONFIG;
const { tagManager, li } = countersLoader;

const onlyProductionContent = renderContent => {
  if (window.location.hostname.indexOf('bina.az') > -1) {
    renderContent();
  }
};

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};
    const { store } = ctx;

    store.dispatch({ type: 'DRAWER_TOGGLE', payload: false });

    try {
      pageProps = Component.getInitialProps
        ? await Component.getInitialProps(ctx, i18n)
        : {};
    } catch (error) {
      console.log(`Error occurred: ${error}`);
      pageProps.statusCode = 500;
      pageProps.seoData = {};
    }

    return {
      pageProps
    };
  }

  componentDidMount() {
    const { apolloClient } = this.props;
    NProgress.configure({ showSpinner: false, trickleSpeed: 50 });
    Router.router.events.on('routeChangeStart', () => {
      NProgress.start();
    });
    onlyProductionContent(() => {
      li();
      tagManager(gtmContainerId);
    });
    Router.router.events.on('routeChangeComplete', () => {
      window.scrollTo(0, 0);
      onlyProductionContent(() => li());
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        NProgress.done(true);
      }, 200);
      if (Router.router.state.route === '/') {
        apolloClient.reFetchObservableQueries();
      }
    });
    document.body.classList.remove('preload');
  }

  render() {
    const { Component, pageProps, apolloClient } = this.props;
    const { seoData, statusCode } = pageProps;
    const seoConfig = createSeoConfig(seoData, fbAppId, i18n);
    const getLayout = Component.getLayout || (page => page);

    if (typeof window !== 'undefined') {
      window.adriverExtentionLoad = adriverCallback;
    }

    if (statusCode === 404) {
      return (
        <I18nextProvider i18n={i18n}>
          <ErrorPage statusCode={statusCode} />
        </I18nextProvider>
      );
    }

    return (
      <I18nextProvider i18n={i18n}>
        {seoConfig ? (
          <>
            <HeadMeta {...seoData.hostSum} />
            <DefaultSeo {...seoConfig} />
          </>
        ) : null}
        <ApolloProvider client={apolloClient}>
          <BridgeProvider>
            {getLayout(
              <ErrorBoundary
                statusCode={statusCode}
                i18n={i18n}
                router={Router}
              >
                <Component {...pageProps} />
              </ErrorBoundary>
            )}
          </BridgeProvider>
        </ApolloProvider>
      </I18nextProvider>
    );
  }
}

export default withApolloClient(reduxWrapper.withRedux(MyApp));
